.ddlc{
    .titleScreen{
        background-size: 10% !important;
        background-image: url('../../assets/ddlc_polka.png') !important;
        background-repeat: repeat !important;
        animation: scroll 40s infinite linear !important;

        .logo{ background-image: url('../../assets/ddlc_logo.png') !important; }

        & > * {
            filter: drop-shadow(0 0 1em rgba(0,0,0,0.3));
            text-shadow: 0 0 .2em black;
            color: rgb(255, 234, 234);
        }
    }
    
    .navbar{
        filter: hue-rotate(90deg);
    }  
}


@keyframes scroll{
    from{background-position: 0%;}
    to  {background-position: 100%;}
}