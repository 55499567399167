.navbar{
    user-select: none;
    width: 100%;
    height: 3em;
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, .3);
    padding: 0 !important;
    box-shadow: 0 0 1em rgba(0, 0, 0, .3);
    transition: all .5s;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .wrapper{
        display: flex;
        flex-direction: row;
        transition: background-color .2s;
        //margin-right: 1em;

        max-width: 60em;
        width: 100%;
        justify-content: space-between;
    }
    img{
        padding-left: 2em;
        cursor: pointer;
    }
    .menu{
        height: 3em;

        .openBtn{
            margin-right: .5em;
            display: none;
            font-size: 2em;
            line-height: 1.5em;
            height: 3em;
            padding: .145em .5em;
            transition: background-color .1s;
        }
        .openBtn:hover{
            background-color: rgba(0, 0, 0, .3);
        }
        @media (max-width: 820px){
            .openBtn{
                display: unset;
            }
            .links{
                display: none !important; 
                position: absolute;
                right: 0;
                flex-direction: column !important;
                .link{
                    background-color: rgba(0,0,0,.3);
                }
            }
            .links.visible{
                display: flex !important; 
            }
        }
        .links{
            display: flex;
            flex-direction: row;
            transition: background-color .2s;
            margin-right: 1em;
            .link{
                padding: 0 1em;
                height: 100%;
                height: 3em;
                line-height: 3em;
                box-shadow: 0;
                transition: box-shadow .2s, background-color .2s;
                cursor: pointer;
                display: flex;
                align-items: center;
            }
            .link:hover{
                box-shadow: inset 0 -.1em 0 white;
                background-color: rgba(0, 0, 0, .3);
            }
            .link.icon{
                width: 1.2em;
            }
        }
    }
}

.scrolledDown{
    background-color: #093164f0 !important;
}
@media (max-width: 820px){
    .scrolledDown{
        .links.visible{
            background-color: #093164f0 !important;
        }
    }
}