.presentationField{
    background: rgba(255, 255, 255, 0.02);
    transition: all .3s;
    padding: 1em;
    border-radius: .5em;
    margin: 1em 0;
    animation: fadeIn 1s;

    .presentation{
        background: white;
        border-radius: 1em;
        overflow: hidden;
        position: relative;
        height: 22em;
        width: 17em;
        margin: 1em .5em;
        background-size: cover;
        background-position: center;
    
        .metadata{
            position: absolute;
            left: 0; right: 0;
            bottom: 0; top: calc(100% - 5em);
            //background:salmon;
            display: flex;
            flex-direction: column;
            padding: 1em;
            color: black;
            box-shadow: rgba(0, 0, 0, .5) 0 0 1em;
            transition: top .2s;
            background:white;
            .title{
                font-weight: bolder;
                //background: mediumslateblue;
            }
            .author{
                font-size: .8em;
                font-style: italic;
                margin-bottom: 2em;
                //background: slategray;
            }
            .description{
                transition:  color 1s;
                font-size: .95em;
                //background: orangered;
                height: 0;
                color: transparent;
                overflow: hidden;
            }
        }
    }
    .presentation:hover{
        .metadata{
            top: 0;
            .description{
                color: inherit;
                height: 100%;
                overflow: auto;
            }
        }
    }
}
.presentationField:hover{
    background: rgba(255, 255, 255, 0.1)
}
.invalidPresentation{
    background: rgba(255, 0, 0, 0.1)
}
.invalidPresentation:hover{
    background: rgba(255, 0, 0, 0.2)
}


@keyframes fadeIn{
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.submitContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    label{
        margin-top: .5em;
    }
}

.registrationOutput{
    opacity: .5;
}