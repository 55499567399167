.harmonogram {
    --hour-width: 500px;
    --event-height: 150px;
    --max-width: calc(var(--hour-width) * 11);
    --var1: 8px;
    --left-margin: 1em;
  
    padding: 0;
    margin: 0;
    white-space: nowrap;
    font-family: Inter, sans-serif;
  
    .timeline {
      display: flex;
      align-items: center;
      width: fit-content;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 5;
      box-shadow: 0 0 8px #000a;
      color: black;
      background: white;
      & > div {
        background: white;
        width: calc(var(--hour-width) / 2 - var(--var1));
        flex-shrink: 0;
        padding: 1em 0 1em var(--var1);
        box-shadow: 1px 0 0 #0002 inset;
      }
      & > div:first-child {
        margin-left: var(--left-margin);
      }
    }
  
    .timelineBackdrop {
      width: fit-content;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      margin-left: var(--left-margin);
      & > div {
        background: repeating-linear-gradient(to right, #f6f6f6, #f6f6f6 50%, #fff 50%, #fff 100%);
        width: calc(var(--hour-width) / 2 - var(--var1));
        flex-shrink: 0;
        padding: 2em 0 calc((var(--event-height) + 1em + 48px) * 10) var(--var1);
      }
      & > div:last-child, .timeline > div:last-child {
        width: calc(var(--hour-width) / 2 - var(--var1) - 1em);
      }
    }
  
    .row {
      width: var(--max-width);
      position: relative;
      .presentations {
        display: flex;
        flex-direction: row;
        height: var(--event-height);
        position: relative;
        margin-left: var(--left-margin);
      }
      .room {
        margin-top: 16px;
        margin-bottom: 16px;
        font-weight: bold;
        color: black;
        box-shadow: 0 0 8px #0004;
        background: #fffb;
        font-size: 1.5em;
        padding: .5em 0em;
        overflow: visible;
        position: relative;
        display: flex; /* Zajistí správné chování sticky */
        align-items: center; /* Zarovnání textu ve středu */
        
        .room-text {
            position: sticky;
            left: 0;
            padding: .0em 1em;
            white-space: nowrap; /* Zabrání zalomení textu */
            width: 200px; /* Přesně definovaná šířka */
        }
    }
    
      .presentation {
        width: 440px;
        border-radius: 8px;
        background-color: #fffe;
        display: flex;
        color: #111;
        position: absolute;
        box-shadow: 0 0 4px #0008;
        padding: 1em 0;
        .metadata {
          padding: 0 0 0 1em;
          justify-content: center;
          display: flex;
          flex-direction: column;
          width: fit-content;
          position: sticky;
          left: 0;
          .title {
            font-weight: bolder;
            font-size: 1.25em;
            white-space: initial;
            padding-right: 1em;
          }
          .subtitle {
            color: #222222;
            font-size: 0.8em;
            width: 100%;
            white-space: normal;
          }
        }
        .icon {
          height: 100%;
          width: var(--event-height);
          background: url("https://firebasestorage.googleapis.com/v0/b/pinoc-2019.appspot.com/o/carina-nebula-1995394_1280.jpg?alt=media");
          background-size: cover;
          background-position: center center;
          position: relative;
          flex-shrink: 0;
          display: none;
          .overlay {
            color: white;
            position: absolute;
            background: #000b;
            width: 100%;
            padding: 0.5em 0;
            text-align: center;
            font-size: 0.5em;
            bottom: 0;
          }
        }
        .follow {
          display: flex;
          align-items: center;
          font-size: 50px;
          padding: 25px;
          position: absolute;
          right: 0;
          height: 100%;
          display: none;
        }
      }
    }
  
    .event {
      background: #f7b1b170 !important;
    }
  
    .pres {
      background: #b7c9fcc5 !important;
    }
  
    .mainpres {
      background: #91a5faad !important;
    }
}
