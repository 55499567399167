.organizers{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 2em;

    .organizerCard{
        display: flex;
        flex-direction: row;
        margin: 1em;
        padding: 1em;
        flex-wrap: wrap;
        background: rgba(0, 0, 0, .2);
        border-radius: 1em;
    
        box-shadow: 0;
        transition: box-shadow .2s;
    
        .image{
            width: 7.5em;
            height: 10em;
            margin-right: 1.5em;
            background-size: cover;
            border-radius: .5em;
            background-position: 50%;
        }
        .info{
            width: 14em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .nameplate{
            display: flex;
            flex-direction: column;
            span:nth-child(1){
                font-size: 2em;
                font-weight: bold;
            }
            span:nth-child(2){
                font-size: .8em;
            }
        }
    }
    .organizerCard:hover{
        box-shadow: 0 .2em 1em rgba(180, 180, 180, .12);
    }

    .organizerCard.knob{
        transform: perspective(50vw);
        transform-style: preserve-3d;
        position: relative;

        .flippy{
            position: absolute;
            transform-origin: left;
            transform: rotateY(0deg);
            transition: transform 2s, filter 1.9s;
            transition-timing-function: cubic-bezier(1.0, 0.0, 0.0, 1.0);
            filter: brightness(1);
        }
        .backdrop {
            box-shadow: black 0 0 4em inset;
            display: flex;
            justify-items: center;
            align-items: center;
            text-align: center;
            color: rgba(255, 255, 255, 0.2);
            user-select: none;
        }
    }
    .organizerCard.knob:hover{
        .flippy{
            transform: rotateY(-80deg);
            filter: brightness(.5);
        }
    }
}

.galleryScreen{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #1b2335;
    padding: 2em;
    
    & > * {
        width: 100%;
        max-width: 60em;
    }
    .gallerySection{
        h2{
            border-bottom: 1px solid rgba(255,255,255,0.2);
        }
        .galleryListing{
            max-width: 60em;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
        .galleryItem{
            transform: translateZ(0);
            animation: enlarge 1s;
            background-color: rgba(0, 0, 0, 0.2);
            flex-shrink: 0;
            margin: 1em;
            width: 7em; height: 7em;
            background-size: cover;
            background-position: center;
            transition: transform 0.2s, box-shadow 0.2s;
            box-shadow: 0;
            transform: scale3d(1,1,1);
            border-radius: 0.3em;
        }
        .galleryItem:hover{
            transform: scale3d(1.1, 1.1, 1.1);
            background-position: center;
            box-shadow: steelblue 0 0 1em;
        }
    
        .loadButton{
            font-size: 1.25em;
            background-color: rgba(255, 255, 255, 0.1);
            padding: .5em 1em;
            border-radius: 0.2em;
            transition: background-color .2s, box-shadow .2s;
            user-select: none;
        }
        .loadButton:hover{
            background-color: rgba(150, 150, 255, 0.1);
            box-shadow: rgba(255, 255, 255, 0.05) 0 0 1em;
            cursor: pointer;
        }
        .loadButton:active{
            box-shadow: 0;
            background-color: rgba(120, 120, 120, 0.1);
        }
    }
}

.fullscreenOverlay{
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0; top: 0;
    width: 100%; height: 100%;
    max-width: unset;
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .closeButton{
        position: absolute;
        top: 2em; right: 2em;
        width: 3em; height: 3em;
        background-color: rgba(0, 0, 0, .8);
        box-shadow: rgba(255, 255, 255, 0.6) 0 0 .25em;
        border-radius: 100%;
        overflow:hidden;
        transition: box-shadow .2s;
        .icon{
            background:white;
            width: 100%;height:100%;
            clip-path: polygon(30% 25%, 25% 30%, 45% 50%, 25% 70%, 30% 75%, 50% 55%, 70% 75%, 75% 70%, 55% 50%, 75% 30%, 70% 25%, 50% 45%);
        }
    }
    .closeButton:hover{
        box-shadow: rgba(255, 255, 255, 0.6) 0 0 1em;
    }
}

@keyframes enlarge{
    0%{height:0;margin:0 1em;}
    100%{height:7em;margin:1em;}
}

.textScreen{
    display: flex;
    //justify-content: center;
    align-items: center;
    padding: 2em;
    flex-direction: column;
    background: #1a1a23;
    & > * {
        max-width: 60em;
        width: 100%;
    }
    & p {
        text-align: justify;
    }
    code {
        background: #fff2;
        padding: .25em;
        border-radius: .3em;
    }
}

.titleScreen{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100vh;
    background-color: transparent;
    background-image: url('../../../assets/background.svg');
    background-position-x: 50%;
    background-position-y: 0px;
    background-attachment: fixed;
    background-size: 600% 200vh;
    background-repeat: no-repeat;
    transition: background-position-y .2s ease-out;

    .logo{
        background-image: url('../../../assets/pinoc_logo_visible.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 55vmin;
        height: 55vmin;
        min-width: 15em;
        min-height: 15em;
        position: relative;
    }
    .desc{
        margin-top: 2rem;
        font-size: 4vmin;
        margin-bottom: 2rem;
        font-weight: bolder;
    }
    .buttons{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        margin-top: 4.5rem;
        font-size: 1em;
        position: absolute;
        bottom: 6vmin;

        .button{
            user-select: none;
            background-color: steelblue;
            padding: 1em 1.5em;
            margin: .5em 1em;
            border-radius: 5em;
            transition: all .15s;
            cursor: pointer;
        }
        .button:hover{
            background-color: #60a0d4;
        }
        .button:active{
            background-color: #335570;
        }
    }
}

.wahScreen{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    font-family: monospace;

    textarea{
        background: transparent;
        color: white;
        border: none;
        width: 100% !important;
        height: 16em !important;
        font-family: monospace;
        resize: none;
    }
    textarea:focus{ outline: none; }

    .gameScreen{
        padding: 1rem;
        background: black;
        width: calc(100% - 5rem);
        height: calc(100% - 5rem);
        border-radius: 1rem;
        box-shadow: #0009 0 .5rem 1rem;
        color: white;
    }
}