label{
    font-weight: bolder;
    margin-top: 1em;
}

.inputKeyPair{
    display: flex;
    flex-direction: column;
    input[type=checkbox]:disabled+label::before,*:disabled{ opacity: .6; }
    
    textarea, input{
        margin: 1em 0;
        font-size: 1em;
        padding: .5em;
        border-radius: .5em;
        border: none;
        font-family: inherit;
        width: calc(100% - 1em);
        background: #fff;
        outline: 0;
        margin-bottom: 0;
        color: black;
    }
    input[type=checkbox] {
        display: none;
        visibility: hidden;
        margin: 0;
        padding: 0;
    }
    input[type=checkbox]+label {
        position: relative;
        padding: 0;
        margin-bottom: 1em;
    }
    input[type=checkbox]+label::before {
        background-color: #fff;
        content: '';
        display: inline-block;
        margin-right: 1em;
        width: 1.5em;
        height: 1.5em;
        border-radius: .5em;
        top: .3em;
        position: relative;
    }
    input[type=checkbox]:checked+label:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 16px;
        background: #000;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 #000, 4px 0 0 #000, 4px -2px 0 #000, 4px -4px 0 #000, 4px -6px 0 #000, 4px -8px 0 #000;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        user-select: none;
    }
    input[type=button], input[type=submit]{
        place-self: flex-end;
        width: fit-content;
        padding: .5em 1em;
        margin: 0;
        transition: all .4s;
    }
    input[type=button]:hover, input[type=submit]:hover{
        box-shadow: #fffa 0 0 .4em,;
    }
    input[type=button]:active, input[type=submit]:active{
        opacity: .7;
        box-shadow: black 0 0 .4em;
    }
    input[type=button]:disabled, input[type=submit]:disabled{
        box-shadow: none!important;
    }

    /* beautiful fucking sliders */
    input[type=range]{
        width: calc(100% - 1em) !important;
        -webkit-appearance: none;
        background: #eee;
        overflow: hidden;
        margin-top: 1em;
        height: 1em;
        width: 100%;
        padding: .5em;
    }
    input[type=range] + .visualHelper{
        display: flex; justify-content: space-around;
        /* margin: 0 1em; */
        position: relative;
        top: -1.7em;
        pointer-events: none;
        color: #101010;
    }
    input[type=range] + .visualHelper * {
        transition: all .2s;
    }
    /* FUCKING BROWSER STANDARTS, USE ONE AND DONT FUCK WITH IT */
    input[type=range]::-webkit-slider-thumb{ -webkit-appearance: none; height:100vh; width:25%; background: white; box-shadow: 0 0 .5em rgba(0, 0, 0, .2); }
    input[type=range]::-moz-range-thumb{     -webkit-appearance: none; height:100vh; width:25%; background: white; box-shadow: 0 0 .5em rgba(0, 0, 0, .2); }
    input[type=range]::-ms-thumb{            -webkit-appearance: none; height:100vh; width:25%; background: white; box-shadow: 0 0 .5em rgba(0, 0, 0, .2); }
    
    input[type=range]::-webkit-slider-runnable-track{ -webkit-appearance: none; }
    input[type=range]::-moz-range-track{              -webkit-appearance: none; }
    input[type=range]::-ms-track{                     -webkit-appearance: none; }

    div{
        color: rgb(255, 146, 146);
        font-weight: bold; font-style: italic;
    }
}
.inRow{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.delimiter{
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: .3;
    margin: .75em 0;
}
.registrationForm, .conditions{
    width: 100%;
    max-width: 28em;
    padding-bottom: 1em;
}
.conditions{
    background: #ffffff22;
    padding: .5em 1em;
    border-radius: .75em;
}