html{
    background-color: rgb(26, 26, 35) !important;/*#221815;*/
    color-scheme: dark;
}
body{
    background-color: rgb(26, 26, 35);/*#221815;*/
    padding: 0; margin: 0;
    font-family: 'Roboto', 'Inter', sans-serif;
    font-size: 1em;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow-y: overlay;
}

.button{
    color: white !important;
    border: none !important;
}

a:visited { color: #7fb4be; }
a:link { color: #93ddeb; }
a { text-decoration: none; }

.noscroll{
    margin: 0; height: 100%; overflow: hidden;
}

.buttcheek{
    display: flex; flex-direction: row; flex-wrap: wrap;
    justify-content: space-around;
}

.konami{
    overflow: hidden;
    animation: maxHeightFade 1s;
}
@keyframes maxHeightFade{
    0% { max-height: 0vh; }
    100% { max-height: 100vh; }
}

.userinfo *{
    box-shadow: none !important;
}
.userinfo td, .userinfo th{
    //padding-left:  0 !important;
    //padding-right: 0 !important;
    box-shadow: none !important;
}

.bp3-file-input{
    margin-top: 0 !important;
}

.whiteText { color: white !important; }
.grouping{
    display: flex;
    flex-direction: row;
    margin: 1em 0;
}
.grouping > *:first{
    margin: 0 !important;
}
.grouping > * {
    margin: 0 .5em !important;
}

.bp3-popover-backdrop{
    background: #000a !important;
}

.presentationGroup{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.presentationGroup.tiny > .presentation{
    height: 15em;
}

.presentation{
    background: white;
    border-radius: 1em;
    overflow: hidden;
    position: relative;
    height: 22em;
    width: 17em;
    margin: 1em .5em;
    background-size: cover;
    background-position: center;

    .metadata{
        position: absolute;
        left: 0; right: 0;
        bottom: 0; top: calc(100% - 5em);
        //background:salmon;
        display: flex;
        flex-direction: column;
        padding: 1em;
        color: black;
        box-shadow: rgba(0, 0, 0, .5) 0 0 1em;
        transition: top .2s;
        background:white;
        .title{
            font-weight: bolder;
            //background: mediumslateblue;
        }
        .author{
            font-size: .8em;
            font-style: italic;
            margin-bottom: 2em;
            //background: slategray;
        }
        .description{
            transition:  color 1s;
            font-size: .95em;
            //background: orangered;
            height: 0;
            color: transparent;
            overflow: hidden;
        }
    }
}
.presentation:hover{
    .metadata{
        top: 0;
        .description{
            color: inherit;
            height: 100%;
            overflow: auto;
        }
    }
}

.lastMinuteButton{
    padding: 1em 1.5em;
    font-family: inherit;
    font-size: 1.1em;

    border-radius: 1em;
    border: none;
    cursor: pointer;
    background: #3A3A52;
    color: white;
    transition: background .1s;
}

.lastMinuteButton:hover{
    background: #44455C;
}

.lastMinuteButton:active{
    background: #252941;
}

.lastMinuteButton:nth-child(1){
    margin-bottom: 1em;
}

.aah{
    margin-top: 1em;
    margin-bottom: 1em;
}

// dummy tailwind
@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
.opacity-30 { opacity: .3; }
.opacity-70 { opacity: .7; }
.animate-spin { animation: rotate 1s linear infinite }
.w-3 { width:  1em; }
.h-3 { height: 1em; }